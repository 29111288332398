import { Box, Flex, Progress, Text } from "@chakra-ui/react";
import { Plan } from "interface/Plan";
import { EMPTY_ADDRESS, fromWei } from "utils/string";

function ProgressTarget(plan: Plan) {
  const { initialTarget, withdrawn, balance, target, paymentToken } = plan;
  const isBnb = paymentToken.contractAddress === EMPTY_ADDRESS;
  const etherInitialTarget = parseFloat(fromWei(initialTarget));
  const etherWithdrawn = parseFloat(fromWei(withdrawn));
  const etherBalance = parseFloat(fromWei(balance));
  const current =
    etherInitialTarget + etherWithdrawn + (isBnb ? 0 : etherBalance);
  const etherTarget = parseFloat(fromWei(target));

  return (
    <Box>
      <Flex
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
        marginBottom={1}
      >
        <Text fontSize="sm">
          {current} {paymentToken.symbol}
        </Text>
        <Text fontSize="sm">
          {etherTarget} {paymentToken.symbol}
        </Text>
      </Flex>

      <Progress
        value={current}
        max={etherTarget}
        colorScheme="teal"
        size="md"
      />
    </Box>
  );
}

export default ProgressTarget;
