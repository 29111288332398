import { AddIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import { useWeb3React } from "@web3-react/core";
import { IoMdWallet } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { isFeatureEnabled, planOwner as planOwnerState } from "state/plan";
import CreatePlanModal from "./CreatePlanModal";
import { connect, disconnect } from "../state/web3";
import { shortenAddress } from "../utils/string";

function CryptoProfileMenu() {
  const { active, account, activate, deactivate } = useWeb3React();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const planOwner = useRecoilValue(planOwnerState);
  const featureEnabled = useRecoilValue(isFeatureEnabled);

  return (
    <>
      <Flex justifyContent="space-between">
        {!location.pathname.startsWith("/fund") && active ? (
          <Button
            colorScheme="purple"
            size="sm"
            leftIcon={<AddIcon />}
            disabled={!featureEnabled}
            onClick={onOpen}
          >
            Create new Fund
          </Button>
        ) : (
          <Spacer />
        )}
        <Menu placement="bottom-end">
          <MenuButton
            as={Button}
            size="sm"
            leftIcon={<IoMdWallet />}
            rightIcon={<ChevronDownIcon />}
            colorScheme="yellow"
          >
            {active ? shortenAddress(account || "") : "My Account"}
          </MenuButton>
          <MenuList fontSize="sm">
            {!active && (
              <MenuItem color="purple" onClick={() => connect(activate)}>
                Connect Wallet
              </MenuItem>
            )}
            <Divider />
            <MenuItem as={Link} to="/">
              My Fund {planOwner?.slug && `(${planOwner.slug})`}
            </MenuItem>
            {active && (
              <>
                <Divider />
                <MenuItem
                  color="red.500"
                  onClick={() => disconnect(deactivate)}
                >
                  Disconnect
                </MenuItem>
              </>
            )}
          </MenuList>
        </Menu>
      </Flex>
      {isOpen && <CreatePlanModal isOpen={isOpen} onClose={onClose} />}
    </>
  );
}

export default CryptoProfileMenu;
