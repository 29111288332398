import { useCallback, useEffect } from "react";
import { InjectedConnector } from "@web3-react/injected-connector";
import { useWeb3React } from "@web3-react/core";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { AbstractConnector } from "@web3-react/abstract-connector";
import Web3 from "web3";
import { provider } from "web3-core";

type LS_WALLET_CONNECTION_VALUE = "inject" | "walletconnect";

const BSC_CHAIN_ID = parseInt(process.env.REACT_APP_BSC_CHAIN_ID, 10);
const LS_WALLET_CONNECTION = "wallet_connection";
const supportedChainIds = [BSC_CHAIN_ID];

export const injected = new InjectedConnector({
  supportedChainIds,
});

export const walletConnectConnector = new WalletConnectConnector({
  rpc: {
    [BSC_CHAIN_ID]: process.env.REACT_APP_BSC_RPC_URL,
  },
  chainId: BSC_CHAIN_ID,
  supportedChainIds,
});

export const resetWalletConnector = (connector: AbstractConnector) => {
  if (connector && connector instanceof WalletConnectConnector) {
    connector.walletConnectProvider = undefined;
  }
};

export const getLibrary = (provider: provider) => new Web3(provider);

const saveWalletConnectionToLs = (connector?: LS_WALLET_CONNECTION_VALUE) => {
  if (!connector) {
    localStorage.removeItem(LS_WALLET_CONNECTION);
  } else {
    localStorage.setItem(LS_WALLET_CONNECTION, connector);
    localStorage.removeItem("disconnect");
  }
};

export const connectWalletConnect = async (activate: any) => {
  try {
    await activate(walletConnectConnector, undefined, true);
    saveWalletConnectionToLs("walletconnect");
  } catch (e) {
    resetWalletConnector(walletConnectConnector);
    saveWalletConnectionToLs();
    console.error(e);
  }
};

export const connectMetaMask = async (activate: any) => {
  try {
    await activate(injected);
    saveWalletConnectionToLs("inject");
  } catch (e) {
    saveWalletConnectionToLs();
    console.error(e);
  }
};

export const connect = (activate: any) => {
  if (window.ethereum) {
    connectMetaMask(activate);
  } else {
    connectWalletConnect(activate);
  }
};

export const disconnect = (deactivate: any) => {
  saveWalletConnectionToLs();
  localStorage.setItem("disconnect", JSON.stringify(true));
  deactivate();
};

export const Web3Root = () => {
  const { activate, error, deactivate } = useWeb3React();
  const getLsWalletConnection = () =>
    localStorage.getItem(LS_WALLET_CONNECTION);

  const init = useCallback(async () => {
    try {
      const lsWalletConnection =
        getLsWalletConnection() as LS_WALLET_CONNECTION_VALUE;
      const lsDisconnect = localStorage.getItem("disconnect");
      const isAuthorized = await injected.isAuthorized();
      const shouldConnect = isAuthorized || !!lsWalletConnection;

      if (error || !shouldConnect || lsDisconnect) {
        saveWalletConnectionToLs();
        return;
      }

      if (lsWalletConnection === "inject") {
        await connectMetaMask(activate);
      } else {
        await connectWalletConnect(activate);
      }
    } catch (e) {
      saveWalletConnectionToLs();
      console.error(e);
    }
  }, [activate, error]);

  useEffect(() => {
    setInterval(() => {
      const lsWalletConnection =
        getLsWalletConnection() as LS_WALLET_CONNECTION_VALUE;
      if (
        !localStorage.getItem("walletconnect") &&
        lsWalletConnection === "walletconnect"
      ) {
        saveWalletConnectionToLs();
        deactivate();
      }
    }, 500);
  }, [deactivate]);

  useEffect(() => {
    init();
  }, [init]);

  return null;
};

export const hasWeb3 = () => !!window.web3?.currentProvider;
