import {
  Button,
  FormControl,
  GridItem,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
} from "@chakra-ui/react";
import { useWeb3React } from "@web3-react/core";
import { useState } from "react";
import { connect } from "state/web3";

type TransferModalProps = {
  symbol: string;
  isOpen: boolean;
  isLoading: boolean;
  onSend: (val: number) => void;
  onClose: () => void;
};

const NUMBER_OPTIONS = [0.1, 0.5, 1, 5, 10];

const toCurrency = (val: string) => val.replace(/[^0-9.]/g, "");

function TransferModal({
  symbol,
  isOpen,
  isLoading,
  onSend,
  onClose,
}: TransferModalProps) {
  const [value, setValue] = useState(NUMBER_OPTIONS[1].toString());
  const { active, activate } = useWeb3React();

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (active) {
      onSend(parseFloat(value));
    } else {
      connect(activate);
    }
  };

  const isValid = parseFloat(value) > 0;

  return (
    <Modal
      size="sm"
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={onSubmit}>
          <ModalHeader>Send {symbol}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired>
              <InputGroup>
                <Input
                  type="tel"
                  value={toCurrency(value)}
                  onChange={(e) => setValue(e.target.value)}
                />
                <InputRightAddon children={symbol} />
              </InputGroup>
            </FormControl>

            <SimpleGrid columns={NUMBER_OPTIONS.length} marginY={4} gap={3}>
              {NUMBER_OPTIONS.map((option) => (
                <GridItem textAlign="center" key={option}>
                  <Button
                    isFullWidth
                    padding={2}
                    variant={value === option.toString() ? "solid" : "outline"}
                    colorScheme={value === option.toString() ? "teal" : "gray"}
                    onClick={() => setValue(option.toString())}
                  >
                    {option}
                  </Button>
                </GridItem>
              ))}
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="purple"
              type="submit"
              disabled={!isValid || isLoading}
              isLoading={isLoading}
            >
              Send
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export default TransferModal;
