import { Button } from "@chakra-ui/react";
import { BsCoin, BsGift } from "react-icons/bs";

type AddFundButtonProps = {
  isMyPlan: boolean;
  disabled?: boolean;
  onClick: () => void;
};

function AddFundButton({ isMyPlan, disabled, onClick }: AddFundButtonProps) {
  if (isMyPlan) {
    return (
      <Button
        size="xs"
        colorScheme="teal"
        leftIcon={<BsCoin />}
        disabled={disabled}
        onClick={onClick}
      >
        Add Fund
      </Button>
    );
  }

  return (
    <Button
      size="xs"
      colorScheme="teal"
      disabled={disabled}
      leftIcon={<BsGift />}
      onClick={onClick}
    >
      Help this Fund
    </Button>
  );
}

export default AddFundButton;
