import { Box, Container, Divider, Flex, Link } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PageMenu from "./components/PageMenu";
import PlanList from "./components/PlanList";
import RetirementPlan from "./components/RetirementPlan";

function App() {
  return (
    <Router>
      <Flex flexDir="column" alignItems="center" height="full">
        <Container maxW="md" flexGrow={1}>
          <Box marginY={4}>
            <PageMenu />
          </Box>
          <Routes>
            <Route path="/retirement-plan" element={<RetirementPlan />} />
            <Route path="/fund/:slug" element={<PlanList />} />
            <Route path="*" element={<PlanList />} />
          </Routes>
          <Divider marginY={4} />
        </Container>
        <Box fontSize="sm" padding={2} fontWeight="semibold" color="gray.500">
          Made by{" "}
          <Link
            target="_blank"
            href="https://twitter.com/mnovalbs"
            rel="noreferrer"
            color="twitter.700"
            fontWeight="bold"
          >
            @mnovalbs
          </Link>
        </Box>
      </Flex>
    </Router>
  );
}

export default App;
