import Web3 from "web3";
import { Currency } from "../interface/Plan";

export const BNB_VAL = 1_000_000_000_000_000_000;
export const MAX_GAS = Web3.utils.toWei("0.005", "ether");

export function pmt(props: {
  ratePerPeriod: number;
  numberOfPayments: number;
  presentValue: number;
  futureValue: number;
  type?: number;
}) {
  const { ratePerPeriod, numberOfPayments, presentValue, futureValue } = props;
  const type = props.type || 0;

  if (ratePerPeriod !== 0) {
    // Interest rate exists
    const q = Math.pow(1 + ratePerPeriod, numberOfPayments);
    return (
      (ratePerPeriod * (futureValue + q * presentValue)) /
      ((-1 + q) * (1 + ratePerPeriod * type))
    );
  }

  if (numberOfPayments !== 0) {
    // No interest rate, but number of payments exists
    return (futureValue + presentValue) / numberOfPayments;
  }

  return 0;
}

export const formatIDR = (val: number, round?: boolean) => {
  let formattedVal = val;
  if (round) {
    formattedVal = Math.round(val);
  }

  return formattedVal.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });
};

export const formatCurrency = (val: number, currency: Currency) => {
  if (currency === "IDR") {
    return formatIDR(val);
  }

  return val.toLocaleString() + " " + currency;
};

export const formatBnb = (val: number) => val * BNB_VAL;
export const parseFromBnb = (val: number) => val / BNB_VAL;
