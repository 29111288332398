import { Alert, Box } from "@chakra-ui/react";
import { Plan } from "../interface/Plan";
import Card from "./Card";
import CryptoProfileMenu from "./CryptoProfileMenu";
import EmptyPlan from "./EmptyPlan";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  plans as plansState,
  planOwner as planOwnerState,
  getPlans,
  isFeatureEnabled,
  getPlanId,
} from "state/plan";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { hexToString, toBN } from "utils/string";

type PlanListProps = {
  plans: any[];
  ownerId: string;
  onRefresh: () => void;
};

function PlanList({ plans, ownerId, onRefresh }: PlanListProps) {
  const formattedPlans: Plan[] = plans
    .map((plan) => ({
      address: plan[0],
      name: hexToString(plan[1]),
      target: toBN(plan[4]).toString(),
      current: toBN(plan[6]).toString(),
      initialTarget: toBN(plan[6]).toString(),
      withdrawn: toBN(plan[8]).toString(),
      balance: toBN(plan[9]).toString(),
      paymentToken: {
        contractAddress: plan[7][0],
        symbol: hexToString(plan[7][1]),
        decimals: parseInt(plan[7][2]),
      },
    }))
    .filter((plan) => plan.paymentToken.symbol !== "DOGE");

  return (
    <Box>
      {!plans.length && (
        <Alert status="warning">There is no plan created yet.</Alert>
      )}
      {formattedPlans.map((plan) => (
        <Card
          key={plan.name}
          {...plan}
          ownerId={ownerId}
          onRefresh={onRefresh}
        />
      ))}
    </Box>
  );
}

function PlanSection() {
  const slug = (useParams().slug || "") as string;
  const [myPlans, setMyPlans] = useRecoilState(plansState);
  const featureEnabled = useRecoilValue(isFeatureEnabled);
  const planOwner = useRecoilValue(planOwnerState);
  const [plans, setPlans] = useState<any[]>([]);
  const [ownerId, setOwnerId] = useState("");
  const hasPlan = myPlans.length !== 0;

  const onRefresh = async () => {
    if (ownerId) {
      const fetchedPlans = await getPlans(ownerId);
      setPlans(fetchedPlans);
    } else {
      const fetchedPlans = await getPlans(planOwner?.id as string);
      setMyPlans(fetchedPlans);
    }
  };

  useEffect(() => {
    if (slug) {
      getPlanId(slug)
        .then((id) => {
          setOwnerId(id);
          return getPlans(id);
        })
        .then(setPlans);
    } else {
      setOwnerId("");
    }
  }, [slug]);

  return (
    <Box>
      {!featureEnabled && (
        <Alert status="warning" marginBottom={4}>
          Feature is disabled. You can't add your fund for now.
        </Alert>
      )}
      <Box marginBottom={4}>
        <CryptoProfileMenu />
      </Box>
      {!hasPlan && !ownerId && <EmptyPlan />}
      {hasPlan && !ownerId && (
        <PlanList ownerId={ownerId} plans={myPlans} onRefresh={onRefresh} />
      )}
      {ownerId && (
        <PlanList ownerId={ownerId} plans={plans} onRefresh={onRefresh} />
      )}
    </Box>
  );
}

export default PlanSection;
