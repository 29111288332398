import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";
import { FormEvent, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { cleanNumber, toCurrency } from "utils/string";
import { retirement } from "../state/retirement";
import FourPercentInfo from "./FourPercentInfo";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

function RetirementPlanModalForm({ isOpen, onClose }: Props) {
  const [expense, setExpense] = useState("");
  const [currentFund, setCurrentFund] = useState("");
  const [targetFund, setTargetFund] = useState("");
  const [investTime, setInvestTime] = useState("");
  const [inflation, setInflation] = useState("");

  const [initialValue, setRetirementPlan] = useRecoilState(retirement);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    setRetirementPlan({
      expense: cleanNumber(expense),
      currentFund: cleanNumber(currentFund),
      targetFund: cleanNumber(targetFund),
      investTime: cleanNumber(investTime),
      inflation: cleanNumber(inflation),
    });
    onClose();
  };

  useEffect(() => {
    if (isOpen && initialValue) {
      setExpense(initialValue.expense.toString());
      setCurrentFund(initialValue.currentFund.toString());
      setTargetFund(initialValue.targetFund.toString());
      setInvestTime(initialValue.investTime.toString());
      setInflation(initialValue.inflation.toString());
    }
  }, [isOpen, initialValue]);

  return (
    <Modal
      size="sm"
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={onSubmit}>
          <ModalHeader>Retirement Plan</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FourPercentInfo />
            <FormControl isRequired marginY={4}>
              <FormLabel>Current Funds</FormLabel>
              <InputGroup>
                <InputLeftAddon children="Rp" />
                <Input
                  type="tel"
                  placeholder="100.000.000"
                  value={toCurrency(currentFund)}
                  onChange={(e) => setCurrentFund(e.target.value)}
                />
              </InputGroup>
            </FormControl>

            <FormControl isRequired marginY={4}>
              <FormLabel>Monthly Expenses</FormLabel>
              <InputGroup>
                <InputLeftAddon children="Rp" />
                <Input
                  type="tel"
                  placeholder="3.000.000"
                  value={toCurrency(expense)}
                  onChange={(e) => setExpense(e.target.value)}
                />
              </InputGroup>
            </FormControl>

            <FormControl isRequired marginY={4}>
              <FormLabel>Invest Time</FormLabel>
              <NumberInput
                max={1000}
                keepWithinRange={false}
                clampValueOnBlur={false}
                value={investTime}
              >
                <InputGroup>
                  <NumberInputField
                    borderTopRightRadius={0}
                    borderBottomRightRadius={0}
                    placeholder="20"
                    onChange={(e) => setInvestTime(e.target.value)}
                  />
                  <InputRightAddon children="tahun" />
                </InputGroup>
              </NumberInput>
            </FormControl>

            <FormControl isRequired marginY={4}>
              <FormLabel>Return on Investment</FormLabel>
              <NumberInput
                max={100}
                keepWithinRange={false}
                clampValueOnBlur={false}
                value={targetFund}
              >
                <InputGroup>
                  <NumberInputField
                    borderTopRightRadius={0}
                    borderBottomRightRadius={0}
                    placeholder="10"
                    onChange={(e) => setTargetFund(e.target.value)}
                  />
                  <InputRightAddon children="%" />
                </InputGroup>
              </NumberInput>
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Inflation</FormLabel>
              <NumberInput
                max={100}
                keepWithinRange={false}
                clampValueOnBlur={false}
                value={inflation}
              >
                <InputGroup>
                  <NumberInputField
                    borderTopRightRadius={0}
                    borderBottomRightRadius={0}
                    placeholder="4"
                    onChange={(e) => setInflation(e.target.value)}
                  />
                  <InputRightAddon children="%" />
                </InputGroup>
              </NumberInput>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button isFullWidth colorScheme="purple" type="submit">
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export default RetirementPlanModalForm;
