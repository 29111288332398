import { EditIcon } from "@chakra-ui/icons";
import { MenuItem, useDisclosure } from "@chakra-ui/react";
import { Plan } from "interface/Plan";
import { fromWei } from "utils/string";
import CreatePlanModal from "./CreatePlanModal";

type ItemMenuUpdateProps = {
  plan: Plan;
};

function ItemMenuUpdate(props: ItemMenuUpdateProps) {
  const { plan } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <MenuItem icon={<EditIcon />} fontSize="sm" onClick={onOpen}>
        Update
      </MenuItem>

      <CreatePlanModal
        initialData={{
          address: plan.address,
          name: plan.name,
          initialFund: fromWei(plan.initialTarget),
          targetFund: fromWei(plan.target),
          paymentToken: plan.paymentToken,
        }}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
}

export default ItemMenuUpdate;
