import { useDisclosure } from "@chakra-ui/react";
import CreatePlanModal from "./CreatePlanModal";
import EmptyBox from "./EmptyBox";

function EmptyPlan() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <EmptyBox
        title="Create Custom Fund"
        caption="Create your custom crypto fund."
        ctaText="Create Fund Now!"
        onCtaClick={onOpen}
      />

      {isOpen && <CreatePlanModal isOpen={isOpen} onClose={onClose} />}
    </>
  );
}

export default EmptyPlan;
