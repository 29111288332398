import { Box, Flex, Heading, useDisclosure, useToast } from "@chakra-ui/react";
import { Plan } from "interface/Plan";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { getBep20Contract, getPlanContract } from "utils/contract";
import { useWeb3React } from "@web3-react/core";
import { useRecoilValue } from "recoil";
import Web3 from "web3";
import { planOwner as planOwnerState } from "state/plan";
import TransferModal from "./TransferModal";
import ProgressTarget from "./ProgressTarget";
import AddFundButton from "./AddFundButton";
import CardMenu from "./CardMenu";
import { EMPTY_ADDRESS } from "utils/string";

type CardProps = Plan & {
  ownerId: string;
  onRefresh: () => void;
};

function Card(props: CardProps) {
  const { name, ownerId, address, paymentToken, onRefresh } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { account, library } = useWeb3React();
  const toast = useToast();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const planOwner = useRecoilValue(planOwnerState);

  const transfer = async (val: number) => {
    const isBnb = paymentToken.contractAddress === EMPTY_ADDRESS;
    const value = Web3.utils.toWei(val.toString(), "ether");

    if (isBnb) {
      const contract = getPlanContract(address, library);
      await contract.methods.sendBnb().send({
        from: account,
        value,
      });
    } else {
      const bep20Contract = getBep20Contract(
        library,
        paymentToken.contractAddress
      );
      await bep20Contract.methods.transfer(address, value).send({
        from: account,
      });
    }
  };

  const isMyPlan =
    !location.pathname.startsWith("/fund") || ownerId === planOwner?.id;

  const onSend = async (val: number) => {
    try {
      setIsLoading(true);
      await transfer(val);
      onRefresh();
      onClose();
    } catch (e: any) {
      console.error(e);
      toast({
        title: "Error",
        description: e.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const disableAddFundButton = paymentToken.symbol === "DOGE";

  return (
    <Box
      backgroundColor="white"
      borderRadius="md"
      padding={6}
      boxShadow="sm"
      marginY={4}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Heading size="sm">{name}</Heading>
        <Flex alignItems="end">
          <AddFundButton
            disabled={disableAddFundButton}
            isMyPlan={isMyPlan}
            onClick={onOpen}
          />
          <Box marginLeft={2}>
            <CardMenu {...props} isMyPlan={isMyPlan} />
          </Box>
        </Flex>
      </Flex>

      <Box marginBottom={4} marginTop={3}>
        <ProgressTarget {...props} />
      </Box>

      <TransferModal
        symbol={paymentToken.symbol}
        isOpen={isOpen}
        isLoading={isLoading}
        onSend={onSend}
        onClose={onClose}
      />
    </Box>
  );
}

export default Card;
