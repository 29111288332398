import Web3 from "web3";
import FinanceContractBuild from "truffle/build/contracts/Finance.json";
import PlanOwnerContractBuild from "truffle/build/contracts/PlanOwner.json";
import PlanContractBuild from "truffle/build/contracts/Plan.json";
import IBEP20Build from "truffle/build/contracts/IBEP20.json";

const web3 = new Web3(process.env.REACT_APP_BSC_RPC_URL);

export const getFinanceContract = (library?: any) => {
  const financeContract = new (library || web3).eth.Contract(
    FinanceContractBuild.abi as any,
    process.env.REACT_APP_CONTRACT_ADDRESS
  );
  return financeContract;
};

export const getPlanOwnerContract = (address: any, library?: any) => {
  const planOwnerContract = new (library || web3).eth.Contract(
    PlanOwnerContractBuild.abi as any,
    address
  );
  return planOwnerContract;
};

export const getPlanContract = (address: any, library?: any) => {
  const planContract = new (library || web3).eth.Contract(
    PlanContractBuild.abi as any,
    address
  );
  return planContract;
};

export const getBep20Contract = (library: any, address: any) =>
  new library.eth.Contract(IBEP20Build.abi as any, address);
