import { TimeIcon } from "@chakra-ui/icons";
import {
  Alert,
  Box,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { Plan } from "interface/Plan";
import { useCallback } from "react";
import { useEffect, useState } from "react";
import { getPlanContract } from "utils/contract";
import { formatDate, fromWei, shortenAddress, toBN } from "utils/string";

type Props = {
  plan: Plan;
};

type Transaction = {
  actor: string;
  purpose: "1" | "2" | "3";
  value: string;
  date: Date;
};

const PURPOSE_MAP = {
  1: "Add Fund",
  2: "Donate",
  3: "Withdraw",
};

function ItemMenuTransaction(props: Props) {
  const { plan } = props;
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getTransactions = useCallback(async () => {
    const contract = getPlanContract(plan.address);
    const fetchedTransactions = await contract.methods.getTransactions().call();
    const formattedTransactions: Transaction[] = fetchedTransactions.map(
      (transaction: any) => ({
        actor: transaction.actor,
        purpose: transaction.purpose,
        date: new Date(parseInt(transaction.timestamp, 10) * 1000),
        value: toBN(transaction.value).toString(),
      })
    );
    formattedTransactions.reverse();
    setTransactions(formattedTransactions);
  }, [setTransactions, plan.address]);

  useEffect(() => {
    if (isOpen) {
      getTransactions();
    }
  }, [isOpen, getTransactions]);

  return (
    <>
      <MenuItem icon={<TimeIcon />} fontSize="sm" onClick={onOpen}>
        Transactions
      </MenuItem>
      <Modal
        size="lg"
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Transactions</ModalHeader>
          <ModalCloseButton />
          <ModalBody textAlign="center" paddingBottom={4}>
            {!transactions.length && (
              <Alert status="info">
                There is no transaction for this fund.
              </Alert>
            )}

            {!!transactions.length && (
              <Box overflowX="auto">
                <Table variant="unstyled" size="sm" minWidth="460px">
                  <Thead>
                    <Tr>
                      <Th>Date</Th>
                      <Th>Type</Th>
                      <Th>From</Th>
                      <Th textAlign="right">Value</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {transactions.map((transaction) => {
                      const value = parseFloat(fromWei(transaction.value));
                      return (
                        <Tr
                          key={transaction.date.getTime()}
                          color={value > 0 ? "teal" : "red"}
                        >
                          <Td>{formatDate(transaction.date)}</Td>
                          <Td>{PURPOSE_MAP[transaction.purpose]}</Td>
                          <Td>{shortenAddress(transaction.actor)}</Td>
                          <Td textAlign="right">
                            {value}
                            {plan.paymentToken.symbol}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ItemMenuTransaction;
