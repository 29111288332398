import { extendTheme } from "@chakra-ui/react";

// Version 1: Using objects
const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "gray.100",
      },
      "html, #root, body": {
        height: "100%",
      },
    },
  },
});

export default theme;
