import { DownloadIcon } from "@chakra-ui/icons";
import {
  Alert,
  Button,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useWeb3React } from "@web3-react/core";
import { Plan } from "interface/Plan";
import { useCallback } from "react";
import { useEffect, useState } from "react";
import { getPlanContract } from "utils/contract";
import { EMPTY_ADDRESS, toBN } from "utils/string";
import Web3 from "web3";

type WithdrawItemMenuProps = {
  plan: Plan;
};

function ItemMenuWithdraw(props: WithdrawItemMenuProps) {
  const { plan } = props;
  const { library, account } = useWeb3React();
  const toast = useToast();
  const [balance, setBalance] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isBnb = plan.paymentToken.contractAddress === EMPTY_ADDRESS;

  const getBalance = useCallback(async () => {
    const contract = getPlanContract(plan.address, library);

    const getBalance = () =>
      isBnb
        ? contract.methods.getBnbBalance()
        : contract.methods.getBalance(plan.paymentToken.contractAddress);

    const fetchedBalance = await getBalance().call();
    setBalance(toBN(fetchedBalance).toString());
  }, [setBalance, library, plan, isBnb]);

  const withdraw = async () => {
    try {
      setIsLoading(true);
      const contract = getPlanContract(plan.address, library);
      if (isBnb) {
        await contract.methods.withdrawBnb().send({
          from: account,
        });
      } else {
        await contract.methods
          .withdraw(plan.paymentToken.contractAddress)
          .send({
            from: account,
          });
      }
      toast({
        title: "Balance Withdrawn!",
        description: "Balance transferred to your account.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (e: any) {
      console.error(e);
      toast({
        title: "Error",
        description: e.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && library?.eth) {
      getBalance();
    }
  }, [isOpen, library, getBalance]);

  return (
    <>
      <MenuItem icon={<DownloadIcon />} fontSize="sm" onClick={onOpen}>
        Withdraw
      </MenuItem>
      <Modal
        size="sm"
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Withdraw Confirmation</ModalHeader>
          <ModalCloseButton />
          <ModalBody textAlign="center">
            {!!balance && (
              <>
                Are you sure want to withdraw this fund? You will get{" "}
                <b>
                  {Web3.utils.fromWei(balance.toString(), "ether")}{" "}
                  {plan.paymentToken.symbol}
                </b>
              </>
            )}
            {!balance && (
              <Alert status="info">This fund don't have any balance.</Alert>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              colorScheme="red"
              type="button"
              marginRight={2}
              variant="outline"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              colorScheme="purple"
              type="button"
              isLoading={isLoading}
              disabled={isLoading || !balance}
              onClick={withdraw}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ItemMenuWithdraw;
