import { Box, Tab, TabList, Tabs } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";

function PageMenu() {
  const location = useLocation();
  const activeIndex = location.pathname === "/retirement-plan" ? 1 : 0;

  return (
    <Box textAlign="center">
      <Tabs
        variant="soft-rounded"
        colorScheme="purple"
        backgroundColor="white"
        display="inline-block"
        borderRadius="3xl"
        index={activeIndex}
      >
        <TabList>
          <Tab as={Link} to="/">
            Crypto Fund
          </Tab>
          <Tab as={Link} to="/retirement-plan">
            Retirement Plan
          </Tab>
        </TabList>
      </Tabs>
    </Box>
  );
}

export default PageMenu;
