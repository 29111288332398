import { AttachmentIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { Plan } from "interface/Plan";
import { BsThreeDotsVertical } from "react-icons/bs";
import ItemMenuTransaction from "./ItemMenuTransaction";
import ItemMenuUpdate from "./ItemMenuUpdate";
import ItemMenuWithdraw from "./ItemMenuWithdraw";

function CardMenu(
  plan: Plan & {
    isMyPlan: boolean;
  }
) {
  const { isMyPlan } = plan;

  const goToBlockExplorer = () => {
    window.open(
      `${process.env.REACT_APP_BSC_EXPLORER_URL}/address/${plan.address}`
    );
  };

  return (
    <>
      <Menu placement="bottom-end">
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<BsThreeDotsVertical />}
          variant="outline"
          size="xs"
        />
        <MenuList>
          {isMyPlan && <ItemMenuUpdate plan={plan} />}
          {isMyPlan && <ItemMenuWithdraw plan={plan} />}
          <ItemMenuTransaction plan={plan} />
          <MenuItem
            fontSize="sm"
            icon={<ExternalLinkIcon />}
            onClick={goToBlockExplorer}
          >
            Contract
          </MenuItem>
          <MenuItem fontSize="sm" icon={<AttachmentIcon />} isDisabled>
            Get Widget
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}

export default CardMenu;
