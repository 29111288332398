import Web3 from "web3";

export const EMPTY_ADDRESS = "0x0000000000000000000000000000000000000000";

export const shortenAddress = (address: string) => {
  const firstSection = address.slice(0, 4);
  const lastSection = address.slice(address.length - 4);
  return `${firstSection}...${lastSection}`;
};

export const cleanNumber = (val: string) =>
  parseInt(val.replaceAll(",", "").replaceAll(".", ""), 10);

export const toCurrency = (val: string) => {
  let parsedVal = cleanNumber(val);

  if (isNaN(parsedVal)) {
    return "";
  }

  if (parsedVal > 100_000_000_000) {
    parsedVal = 100_000_000_000;
  }

  return parsedVal.toLocaleString("id-ID");
};

export const formatDate = (date: Date): string => {
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

export const fromWei = Web3.utils.fromWei;
export const toWei = Web3.utils.toWei;
export const toBN = Web3.utils.toBN;
export const hexToString = Web3.utils.hexToString;
