import {
  Alert,
  AlertDescription,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  getPlans,
  plans as plansState,
  planOwner as planOwnerState,
  getPlanOwner,
  isFeatureEnabled,
} from "state/plan";
import { getFinanceContract, getPlanContract } from "utils/contract";
import { connect } from "../state/web3";
import { PaymentToken } from "interface/PaymentToken";
import { Bytes32 } from "soltypes";
import { EMPTY_ADDRESS } from "utils/string";

type CreatePlanData = {
  address: string;
  paymentToken: PaymentToken;
  name: string;
  initialFund: string;
  targetFund: string;
};

type CreatePlanModalProps = {
  isOpen: boolean;
  initialData?: CreatePlanData;
  onClose: () => void;
};

const toCurrency = (val: string) => val.replace(/[^0-9.]/g, "");

function CreatePlanModal({
  isOpen,
  initialData,
  onClose,
}: CreatePlanModalProps) {
  const { activate, active, account, library } = useWeb3React();
  const toast = useToast();
  const setPlans = useSetRecoilState(plansState);
  const featureEnabled = useRecoilValue(isFeatureEnabled);
  const [planOwner, setPlanOwner] = useRecoilState(planOwnerState);

  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [paymentTokens, setPaymentTokens] = useState<PaymentToken[]>([]);
  const [selectedPaymentToken, setSelectedPaymentToken] =
    useState<PaymentToken | null>(initialData?.paymentToken || null);
  const [slug, setSlug] = useState("");
  const [targetFund, setTargetFund] = useState(initialData?.targetFund || "");
  const [initialFund, setInitialFund] = useState(
    initialData?.initialFund || ""
  );
  const [name, setName] = useState(initialData?.name || "");

  const toWei = Web3.utils.toWei;
  const stringToHex = Web3.utils.stringToHex;

  const createPlan = async () => {
    if (!planOwner || !selectedPaymentToken) {
      return;
    }

    const contract = getFinanceContract(library);
    const bnbTargetFund = toWei(targetFund, "ether");
    const bnbInitialFund = toWei(initialFund, "ether");
    const nameBytes32 = stringToHex(name);
    const tokenSymbol = stringToHex(selectedPaymentToken.symbol);

    await contract.methods
      .addPlan(nameBytes32, 0, 0, bnbTargetFund, true, bnbInitialFund, [
        selectedPaymentToken.contractAddress,
        Bytes32.from(tokenSymbol).toString(),
        selectedPaymentToken.decimals,
        true,
      ])
      .send({
        from: account,
      });

    const fetchedPlans = await getPlans(planOwner.id);
    setPlans(fetchedPlans);
    onClose();
  };

  const createPlanOwner = async () => {
    const contract = getFinanceContract(library);
    const slugBytes32 = Web3.utils.stringToHex(slug);
    await contract.methods.addPlanOwner(slugBytes32).send({
      from: account,
    });
    const fetchedPlanOwner = await getPlanOwner(account);
    setPlanOwner(fetchedPlanOwner);

    toast({
      title: "Root Path Created!",
      description: "Now you can create your plan",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const updatePlan = async () => {
    if (!planOwner || !selectedPaymentToken || !initialData) {
      return;
    }

    const contract = getPlanContract(initialData.address, library);
    const bnbTargetFund = toWei(targetFund, "ether");
    const nameBytes32 = stringToHex(name);

    await contract.methods
      .updatePlan(nameBytes32, 0, 0, bnbTargetFund, true)
      .send({
        from: account,
      });

    const fetchedPlans = await getPlans(planOwner.id);
    setPlans(fetchedPlans);
    onClose();
  };

  const onPaymentTokenChange = (e: any) => {
    const paymentToken = paymentTokens.find(
      (pt) => pt.contractAddress === e.target.value
    );

    if (paymentToken) {
      setSelectedPaymentToken(paymentToken);
    }
  };

  const submit = async (e: any) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      if (!planOwner) {
        await createPlanOwner();
      } else if (initialData?.address) {
        await updatePlan();
      } else {
        await createPlan();
      }
    } catch (e: any) {
      console.error(e);
      toast({
        title: "Error",
        description: e.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getPaymentToken = async (id: number) => {
    const result = await getFinanceContract().methods.paymentTokens(id).call();
    return result;
  };

  const getAvailablePaymentToken = useCallback(async () => {
    try {
      setIsFetching(true);
      const totalPaymentTokens = await getFinanceContract()
        .methods.totalPaymentToken()
        .call();
      const results = await Promise.all(
        [...new Array(parseInt(totalPaymentTokens, 10))].map((_, i) =>
          getPaymentToken(i + 1)
        )
      );
      const bnbPaymentToken: PaymentToken = {
        symbol: "BNB",
        contractAddress: EMPTY_ADDRESS,
        decimals: 18,
        isActive: true,
      };
      const filteredPaymentTokens: PaymentToken[] = results
        .filter((result) => result.isActive)
        .map((result) => ({
          isActive: true,
          contractAddress: result.contractAddress,
          decimals: parseInt(result.decimals, 10),
          symbol: Web3.utils.hexToString(result.symbol),
        }));
      setPaymentTokens([bnbPaymentToken].concat(filteredPaymentTokens));
      if (!selectedPaymentToken) {
        setSelectedPaymentToken(bnbPaymentToken);
      }
    } catch (e: any) {
      console.error(e);
      toast({
        title: "Error",
        description: e.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsFetching(false);
    }
  }, [toast, selectedPaymentToken]);

  useEffect(() => {
    getAvailablePaymentToken();
  }, [getAvailablePaymentToken]);

  return (
    <Modal
      size="sm"
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={submit}>
          <ModalHeader>
            {initialData ? "Update" : "Create"} Custom Fund
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody textAlign="center">
            {!active && (
              <Box
                padding={4}
                borderStyle="dashed"
                borderWidth={1}
                borderColor="blackAlpha.400"
              >
                <Text fontSize="sm">Please connect your wallet first</Text>
                <Button
                  size="sm"
                  colorScheme="yellow"
                  mt={2}
                  onClick={() => connect(activate)}
                >
                  Connect Wallet
                </Button>
              </Box>
            )}

            {active && (
              <>
                {!planOwner && (
                  <>
                    <Alert status="info" fontSize="sm" marginBottom={4}>
                      <AlertDescription>
                        Before create the plan, you must to create your root
                        path. This just like your username
                      </AlertDescription>
                    </Alert>
                    <FormControl isRequired>
                      <FormLabel fontSize="sm">Root URL</FormLabel>
                      <InputGroup size="sm">
                        <InputLeftAddon
                          children={`${window.location.origin}/fund/`}
                        />
                        <Input
                          autoFocus
                          size="sm"
                          value={slug}
                          placeholder="your-url"
                          maxLength={32}
                          onChange={(e) => setSlug(e.target.value)}
                        />
                      </InputGroup>
                    </FormControl>
                  </>
                )}

                {planOwner && (
                  <>
                    <FormControl isRequired>
                      <FormLabel fontSize="sm">Payment Token</FormLabel>
                      <Select
                        size="sm"
                        disabled={!!initialData}
                        value={selectedPaymentToken?.contractAddress}
                        onChange={onPaymentTokenChange}
                      >
                        {paymentTokens.map((pt) => (
                          <option
                            key={pt.contractAddress}
                            value={pt.contractAddress}
                          >
                            {pt.symbol}
                          </option>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl isRequired marginY={4}>
                      <FormLabel fontSize="sm">Fund Name</FormLabel>
                      <Input
                        autoFocus
                        size="sm"
                        value={name}
                        maxLength={32}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </FormControl>

                    <FormControl marginY={4} isRequired>
                      <FormLabel fontSize="sm">Initial Funds</FormLabel>
                      <InputGroup size="sm">
                        <Input
                          type="tel"
                          placeholder="2"
                          value={toCurrency(initialFund)}
                          disabled={!!initialData}
                          onChange={(e) => setInitialFund(e.target.value)}
                        />
                        <InputRightAddon
                          children={
                            selectedPaymentToken?.symbol || "loading..."
                          }
                        />
                      </InputGroup>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel fontSize="sm">Target Funds</FormLabel>
                      <InputGroup size="sm">
                        <Input
                          type="tel"
                          placeholder="10"
                          value={toCurrency(targetFund)}
                          onChange={(e) => setTargetFund(e.target.value)}
                        />
                        <InputRightAddon
                          children={
                            selectedPaymentToken?.symbol || "loading..."
                          }
                        />
                      </InputGroup>
                    </FormControl>
                  </>
                )}
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="purple"
              disabled={!active || isLoading || !featureEnabled || isFetching}
              type="submit"
              isLoading={isLoading || isFetching}
            >
              {initialData ? "Update" : "Create"}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export default CreatePlanModal;
