import { Box, Button, Heading, Text } from "@chakra-ui/react";

type Props = {
  title: string;
  caption: string;
  ctaText: string;
  onCtaClick: () => void;
};

function EmptyBox(props: Props) {
  const { title, caption, ctaText, onCtaClick } = props;

  return (
    <Box
      borderStyle="dashed"
      borderWidth={1}
      borderColor="blackAlpha.400"
      padding={4}
      textAlign="center"
    >
      <Heading size="sm">{title}</Heading>
      <Text fontSize="xs">{caption}</Text>

      <Button colorScheme="purple" size="sm" marginTop={2} onClick={onCtaClick}>
        {ctaText}
      </Button>
    </Box>
  );
}

export default EmptyBox;
