import { atom, AtomEffect, selector } from "recoil";
import { RetirementPlan } from "../interface/Plan";
import { pmt } from "../utils/number";

function localStorageEffect(
  key: string
): AtomEffect<RetirementPlan | undefined> {
  return ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _: any, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };
}

export const retirement = atom<RetirementPlan | undefined>({
  key: "retirement",
  default: undefined,
  effects_UNSTABLE: [localStorageEffect("retirement_plan")],
});

export const retirementPmt = selector({
  key: "retirementPmt",
  get: ({ get }) => {
    const retirementPlan = get(retirement);

    if (!retirementPlan) {
      return 0;
    }

    const { expense, currentFund, targetFund, investTime, inflation } =
      retirementPlan;
    const annualExpense = expense * 12;
    const nextExpense =
      annualExpense * Math.pow(1 + inflation / 100, investTime);
    const neededFund = nextExpense / (4 / 100);

    const result = pmt({
      ratePerPeriod: targetFund / 100,
      numberOfPayments: investTime,
      presentValue: currentFund * -1,
      futureValue: neededFund,
      type: 0,
    });

    return Math.round(result);
  },
});
