import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect } from "react";
import { atom, useRecoilState, useSetRecoilState } from "recoil";
import { getFinanceContract } from "utils/contract";

type PlanOwner = {
  slug: string;
  id: string;
  totalPlan: number;
};

export const plans = atom<any[]>({
  key: "plans",
  default: [],
});

export const planOwner = atom<PlanOwner | null>({
  key: "planOwner",
  default: null,
});

export const isFeatureEnabled = atom<boolean>({
  key: "isFeatureEnabled",
  default: true,
});

export const getPlanOwner = async (account: any): Promise<PlanOwner | null> => {
  const contract = getFinanceContract();
  const myPlanOwner = await contract.methods
    .getPlanOwnerByAddress(account)
    .call();

  if (myPlanOwner[0] === "0") {
    return null;
  }

  return {
    id: myPlanOwner[0],
    slug: Web3.utils.hexToString(myPlanOwner[1]),
    totalPlan: myPlanOwner[2],
  };
};

export const getPlanDetail = async (ownerId: string, index: number) => {
  const contract = getFinanceContract();
  const fetchedPlanDetail = await contract.methods
    .getPlan(ownerId, index)
    .call();
  return fetchedPlanDetail;
};

export const getPlanId = async (slug: string) => {
  const contract = getFinanceContract();
  const id = await contract.methods.slugs(Web3.utils.stringToHex(slug)).call();
  return id;
};

export const getPlans = async (ownerId: string) => {
  const contract = getFinanceContract();
  const planOwner = await contract.methods.getPlanOwnerById(ownerId).call();
  const totalPlan: number = parseInt(planOwner[2], 10);

  const fetchedPlans = await Promise.all(
    [...Array(totalPlan)].map((_, index: number) =>
      getPlanDetail(ownerId, index)
    )
  );
  return fetchedPlans;
};

export const PlanRoot = () => {
  const { account, active } = useWeb3React();
  const setPlans = useSetRecoilState(plans);
  const setFeatureEnabled = useSetRecoilState(isFeatureEnabled);
  const [po, setPo] = useRecoilState(planOwner);

  const fetchPlanOwner = useCallback(async () => {
    console.log("fetch po");
    const fetchedPlanOwnerSlug = await getPlanOwner(account);
    setPo(fetchedPlanOwnerSlug);
  }, [account, setPo]);

  const fetchPlans = useCallback(async () => {
    if (po) {
      console.log("fetch plans");
      const fetchedPlans = await getPlans(po.id);
      setPlans(fetchedPlans);
    }
  }, [po, setPlans]);

  const getFeatureSetting = useCallback(async () => {
    const contract = getFinanceContract();
    const result = await contract.methods.featureEnable().call();
    setFeatureEnabled(result);
  }, [setFeatureEnabled]);

  useEffect(() => {
    if (po?.id && active) {
      fetchPlans();
    }
  }, [active, po?.id, fetchPlans]);

  useEffect(() => {
    if (active && account) {
      fetchPlanOwner();
    }
  }, [active, account, fetchPlanOwner]);

  useEffect(() => {
    if (!active || !po?.id) {
      console.log("reset plan state");
      setPo(null);
      setPlans([]);
    }
  }, [active, po?.id, setPo, setPlans]);

  useEffect(() => {
    getFeatureSetting();
  }, [getFeatureSetting]);

  return null;
};
