import {
  Alert,
  AspectRatio,
  Box,
  Link,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  ModalFooter,
  Button,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";

const VIDEO_URLS = [
  "https://www.youtube.com/embed/c9aHp-nvM1g",
  "https://www.youtube.com/embed/Eq1iKCWQn4U",
  "https://www.youtube.com/embed/e0fcoJhJ_I0",
];

function FourPercentInfo() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Alert status="info" fontSize="sm" variant="left-accent">
        <Box>
          This site uses the four percent rules calculation. See an explanation
          of the four percent rule in{" "}
          <Link
            fontWeight="semibold"
            textDecoration="underline"
            onClick={onOpen}
          >
            Raditya Dika / Doddy Bicara Investasi / Felicia Putri video here
          </Link>
          .
        </Box>
      </Alert>

      <Modal size="lg" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reference Videos</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack gap={4}>
              {VIDEO_URLS.map((url) => (
                <AspectRatio ratio={16 / 9} borderRadius="lg" overflow="hidden">
                  <iframe
                    width="100%"
                    src={url}
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </AspectRatio>
              ))}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default FourPercentInfo;
