import { EditIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { RetirementPlan as IRetirementPlan } from "../interface/Plan";
import { retirement, retirementPmt } from "../state/retirement";
import { formatIDR } from "../utils/number";
import EmptyBox from "./EmptyBox";
import RetirementPlanModalForm from "./RetirementPlanModalForm";

type Row = [string, string, string];
type RowSectionProps = {
  title: string;
  rows: Row[];
};

function EmptyRetirementPlan() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <EmptyBox
        title="Create Retirement Plan"
        caption="We're using 4% rule to calculate your retirement plan investment."
        ctaText="Create Plan Now!"
        onCtaClick={onOpen}
      />
      <RetirementPlanModalForm isOpen={isOpen} onClose={onClose} />
    </>
  );
}

function RowSection(props: RowSectionProps) {
  const { title, rows } = props;

  return (
    <Box padding={3} marginY={4} borderWidth="thin" borderStyle="dotted">
      <Heading size="sm" marginBottom={2}>
        {title}
      </Heading>
      <Table size="sm">
        <Tbody>
          {rows.map(([title, value, color]) => (
            <Tr key={title}>
              <Td pl={0} fontWeight="medium" borderWidth={0}>
                {title}
              </Td>
              <Td pr={0} textAlign="right" color={color} borderWidth={0}>
                {value}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}

function PlanResult() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const pmtResult = useRecoilValue(retirementPmt);
  const { expense, investTime, inflation, targetFund, currentFund } =
    useRecoilValue(retirement) as IRetirementPlan;
  const annualExpense = expense * 12;
  const annualExpenseAtRetirement =
    annualExpense * Math.pow(1 + inflation / 100, investTime);

  const rows: Row[] = [
    [
      "Total Investment",
      formatIDR(annualExpenseAtRetirement / 0.04, true),
      "black",
    ],
    ["Annual Expense", formatIDR(annualExpenseAtRetirement, true), "red"],
  ];

  const planRows: Row[] = [
    ["Annual Savings", formatIDR(pmtResult, true), "green"],
    ["Monthly Savings", formatIDR(pmtResult / 12, true), "green"],
    ["Invest Time", `${investTime} years`, "black"],
    ["Invest Return", `${targetFund}%`, "green"],
    ["Inflation", `${inflation}%`, "black"],
  ];

  const configRows: Row[] = [
    ["Initial Fund", formatIDR(currentFund, true), "green"],
    ["Monthly Expenses", formatIDR(expense, true), "red"],
  ];

  return (
    <>
      <Box backgroundColor="white" borderRadius="md" padding={6} boxShadow="sm">
        <Flex
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
          marginBottom={4}
        >
          <Heading size="md">Retirement Plan</Heading>
          <Button size="xs" onClick={onOpen}>
            <EditIcon />
          </Button>
        </Flex>
        <RowSection title="Retirement" rows={rows} />
        <RowSection title="Your Plan" rows={planRows} />
        <RowSection title="Your Info" rows={configRows} />
      </Box>
      <RetirementPlanModalForm isOpen={isOpen} onClose={onClose} />
    </>
  );
}

function RetirementPlan() {
  const retirementPlan = useRecoilValue(retirement);

  return (
    <>
      <Alert
        status="warning"
        fontSize="sm"
        marginBottom={4}
        variant="left-accent"
      >
        <AlertIcon />
        This site will not save your configuration to the internet. All will be
        saved in your local browser storage. So you can't see your last
        configuration on other devices.
      </Alert>
      {!retirementPlan && <EmptyRetirementPlan />}
      {!!retirementPlan && <PlanResult />}
    </>
  );
}

export default RetirementPlan;
